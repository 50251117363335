<template>
  <div
    layout-align="center center"
    class="mb-6"
  >
    <div class="page-topborder" />

    <div
      v-if="topic.id"
      class="page-width pb-3"
    >
      <div
        class="py-3"
      >
        <div
          class="headline font-weight-bold"
          layout="row"
          layout-align="start center"
        >
          <v-icon
            class="ml-2"
            :size="32"
            :color="topic.is_enabled ? 'success' : 'error'"
            :title="topic.is_enabled ? 'Enabled' : 'Disabled'"
          >
            mdi-circle-outline
          </v-icon>
          <span class="ml-2">{{ topic.name }}</span>
        </div>
      </div>
      <router-link
        :to="{name: prevRoute}"
        class="link-unstyled py-2 ml-4 no-printable"
        style="width: max-content"
      >
        <v-icon
          color="primary"
          size="16"
        >
          mdi-arrow-left
        </v-icon>
        Back to the list
      </router-link>
      <div
        layout="row"
        layout-align="start start"
        class="mb-3 no-printable"
        style="background-color: #fff"
      >
        <v-tabs
          class="ml-3 grey"
          flex
        >
          <v-tabs-slider color="#f4b944" />
          <v-tab
            router-link
            :to="{name: 'admin-sessions-topics-show-info', params: {id: topic.id}}"
          >
            Information
          </v-tab>
          <v-tab
            v-if="topic.application === Consts.APPLICATION.FREELANCES"
            router-link
            :to="{name: 'admin-sessions-topics-show-timeslots', params: {id: topic.id}}"
          >
            Time slots
          </v-tab>
          <v-tab
            v-if="topic.application === Consts.APPLICATION.FREELANCES"
            router-link
            :to="{name: 'admin-sessions-topics-show-incompatibilities', params: {id: topic.id}}"
          >
            Incompatibilities rules
          </v-tab>
          <v-tab
            v-if="topic.application === Consts.APPLICATION.FREELANCES"
            router-link
            :to="{name: 'admin-sessions-topics-show-availabilities', params: {id: topic.id}}"
          >
            Availabilities
          </v-tab>
        </v-tabs>
        <v-btn
          class="mx-0"
          large
          icon
          @click="toggle"
        >
          <v-icon>{{ topic.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
        </v-btn>
      </div>
      <router-view
        :key="routerViewKey"
        :value="topic"
        @topic:update="refresh"
        class="px-3"
      />
    </div>
    <div
      v-else
      layout="row"
      class="ma-5"
      layout-align="center center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </div>
  </div>
</template>

<script>
import Consts from "@/consts"
import GlobalMixin from "@/mixins/global"
import Params from "@/api/params"

export default {
  mixins: [GlobalMixin],
  beforeRouteUpdate(to, from, next) {
    if (from.params.id !== to.params.id) {
      this.fetch(to.params.id); // Prevent tab change
    }
    next();
  },
  data() {
    return {
      loading: false,
      topic: {},
      routerViewKey: 1,
      Consts,
      prevRoute: "admin-sessions-topics-index"
    };
  },

  mounted() {
    this.loading = true;
    this.fetch();
  },

  methods: {
    refresh(t) {
      this.topic = t
      this.topic.application = parseInt(this.topic.application)
      this.topic.is_pmr = parseInt(this.topic.is_pmr)
      this.routerViewKey++;
    },
    fetch(id = null) {
      Params.get("topics", id ? id : this.$route.params.id).then(res => {
        this.topic = res.data;
        this.loading = false;
        this.routerViewKey++;
        // this.topic = Object.assign({}, this.user); <<<<<<<---- Cyril : pas sûr de voir à quoi ça a pu servir
      });
    },
    toggle() {
      if (this.topic.is_enabled) {
        Params.disable("topics", this.topic.id).then(() => {
          this.topic.is_enabled = 0;
          this.$snotify.success(
            `Concept "${this.topic.name} ${
              this.topic.name
            }" has been disabled`
          );
        });
      } else {
        Params.enable("topics", this.topic.id).then(() => {
          this.topic.is_enabled = 1;
          this.$snotify.success(
            `Concept "${this.topic.name} ${
              this.topic.name
            }" has been enabled`
          );
        });
      }
    }
  }
};
</script>
<style scoped>
.expo {
  vertical-align: super;
  color: lightgrey;
  font-size: 14px;
}
.edit-icon {
  text-decoration: none;
}

@media print {
  .headline > .v-btn,
  .headline > .v-menu--inline,
  .v-tabs {
    display: none;
  }
  .no-printable {
    display: none;
  }
}
</style>
<style>
@media print {
  .no-printable {
    display: none;
  }
}
</style>
